<template>
  <div>
    <navbar />
    <div class="search-view">
      <div class="search-view-input">
        <div class="search-view-input-view">
          <van-field v-model="value" placeholder="搜索" @input="inputValue" />
        </div>
        <div class="search-view-input-button" @click="clickButton">
          <img src="../assets/images/icon_sousuo.png" class="search-view-input-button-image">
          <span class="search-view-input-button-text">搜索</span>
        </div>
      </div>
      <div class="article-views" v-if="arr.length !==0">
        <div class="article-view-item" v-for="item in arr" :key="item.id" @click="clickArticle(item.id)" >
          <div class="article-view-item-left">
            <img :src="item.thumbnail" style="width: 100%;height: 100%;">
          </div>
          <div class="article-view-item-right">
            <div class="article-view-item-right-title">{{item.title}}</div>
            <div class="article-view-item-right-subtitle">{{item.summary}}</div>
            <div class="article-view-item-right-date">{{item.publish_at}}</div>
          </div>
        </div>

      </div>
      <div class="null-view" v-else>
        暂无数据
      </div>
    </div>
    <van-popup v-model="loading" :close-on-click-overlay="false" class="van-popup-mine">
      <van-loading type="spinner"/>
    </van-popup>
  </div>
</template>

<script>
import navbar from "../components/NavBar";

export default {
  name:"search",
  data(){
    return{
      inputV:"",
      arr:[],
      loading:false
    }
  },
  components: { navbar },
  mounted() {
  },
  methods:{
    inputValue(v){
      this.inputV = v;
    },
    clickButton(){
      this.loading = true;
      this.$http.get(`/articles?keywords=${this.inputV}`).then(res =>{
        // const date = new Date();
        // res.data.data.map(item =>{
        //   item.publish_at = `${date.getFullYear()}年${Number(date.getMonth() + 1)}月${date.getDate()}号`
        // })
        this.arr = res.data.data;
      })
      this.loading = false;
    },
    clickArticle(v){
      this.$router.push({name:"ArticleInfo",query:{id:v}})
    },
  }
}
</script>
<style lang="less">
.search-view{
  margin-top: 160px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 35px;
}
.search-view-input{
  display: flex;
  justify-content: center;
  align-items: center;
  &-view{
    height: 120px;
    border-radius: 5px;
    flex: 1;
  }
  &-button{
    width: 220px;
    height: 120px;
    background: #004F9F;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-image{
      width: 40px;
      height: 40px;
      margin-right: 23px;
    }
    &-text{
      font-size: 42px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
.null-view{
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b1b9;
}
.article-view-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin-bottom: 45px;
  &-left{
    width: 500px;
    height: 275px;
    background: rgba(235, 235, 235, 1);
  }
  &-right{
    width: 100%;
    padding: 38px 41px 0 38px;
    height: 240px;
    background: rgba(235, 235, 235, 1);
    overflow: hidden;
    &-title{
      font-size: 36px;
      font-weight: 400;
      color: #242424;
      margin-bottom: 24px;
      flex: 1;
    }
    &-subtitle{
      font-size: 32px;
      font-weight: 400;
      color: #6C6D6E;
      margin-bottom: 40px;
    }
    &-date{
      font-size: 32px;
      font-weight: 400;
      color: #787878;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
.article-views{
  margin-top: 40px;
}
.van-cell.van-field{
  height: 120px;
  border: 2px solid #B5B5B5;
  border-radius: 5px;
  padding: 0;
  background: rgba(181, 181, 181, 0.3);
}
.van-field__control{
  height: 120px;
  border-radius: 5px;
  border: 0;
  font-size: 42px;
  padding-left: 40px;
}
.van-field__control::placeholder{
  font-size: 42px;
  font-weight: 400;
  color: #979797;
}
.van-popup-mine {
  background: rgba(255, 255, 255, 0);
}
</style>
